/**
 * List of events used by booking steps widget
 *
 */
export const bookingSteps = {
  NEXT_STEP_PROCESS_STARTED: 'BookingSteps.NextStepProcessStarted',
  BOOKING_ITEM_INITIAL_VALUES: 'BookingSteps.InitialValues',
  BOOKING_ITEM_BOOKINGDRAFT_FETCHED: 'BookingSteps.BookingDraftFetched',
  BOOKINGDRAFT_FETCHED_AFTER_ITEM_CHANGED: 'BookingSteps.BookingDraftFetchedAfterItemChanged',
  DATA_CHANGED: 'BookingSteps.BookingItemDataChanged',
  ERROR_MESSAGE: 'BookingSteps.ErrorMessage'
}
