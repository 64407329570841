import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import registeredEvents from '../../../js/helpers/registered-events'

const definition = {
  name: 'c-form',
  props: []
}

export const attr = {
  action: 'action',
  method: 'method',
  track: 'data-track',
  changePatternSettingsId: `data-${definition.name}-change-pattern-settings-id`,
  changePatternFieldSelector: `data-${definition.name}-change-pattern-selector`,
  changePatternIdStart: `data-${definition.name}-change-pattern-id-start`
}

const componentQueries = {
  triggersChangePatternSelector: `[${attr.changePatternSettingsId}]`,
  changePatternSettings: `[data-${definition.name}__change-pattern-configuration]`
}

const defaults = {
  validate: false,
  recaptcha: false
}

export default class Form extends Component {
  /**
   * Creates a new form behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.selectors = {}
    this.fields = []
    this.options = { ...defaults }

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    if (this.element.hasAttribute('data-validate')) {
      this.options.validate = true
    }
    if (this.element.hasAttribute('data-disabled-on-submit')) {
      this.options.disabledOnSubmit = true
    }
    if (this.element.hasAttribute('data-loading-on-submit')) {
      this.options.loadingOnSubmit = true
    }
    if (this.element.hasAttribute('data-c-form__recaptcha')) {
      this.options.recaptcha = true
    }

    this.formInputsSelectors = [
      { api: 'c-textbox', propName: 'value', function: this._getPropValue.bind(this) },
      { api: 'c-dropdown', propName: 'value', function: this._getPropValue.bind(this) },
      { api: 'c-choice-list', propName: 'value', function: this._getChoiceListValue.bind(this) },
      { api: 'c-date-selector', propName: 'date', function: this._getPropValue.bind(this) },
      { api: 'c-phone-input', propName: 'value', function: this._getPropValue.bind(this) }
    ]

    this._init()

    this.element[definition.name].validate = this.validate.bind(this)
    this.element[definition.name].getFormData = this.getFormData.bind(this)
    this.element[definition.name].action = this.element.hasAttribute(attr.action) ? this.element.attributes[attr.action].value : ''
    this.element[definition.name].method = this.element.hasAttribute(attr.method) ? this.element.attributes[attr.method].value : ''
    this.element[definition.name].init = this._init.bind(this)
  }

  _init () {
    if (this.options.validate) {
      this.element.setAttribute('novalidate', true)
      this._setSelectors()
        ._bindValidationEvents()
        ._bindChangePatternEvents()
    }
    if (this.options.recaptcha) {
      this._bindRecaptchaEvents()
    }
    this._bindSubmit()
    return this
  }

  _setSelectors () {
    this.selectors.inputs = this.element.querySelectorAll('.c-form__item.c-textbox')
    this.selectors.textareas = this.element.querySelectorAll('.c-form__item.c-textarea')
    this.selectors.selects = this.element.querySelectorAll('.c-form__item.c-dropdown')
    this.selectors.choices = this.element.querySelectorAll('.c-form__item.c-choice-list')
    this.selectors.dates = this.element.querySelectorAll('.c-form__item.c-date-selector')
    this.selectors.phones = this.element.querySelectorAll('.c-form__item.c-phone-input')
    return this
  }

  _bindValidationEvents () {
    this.selectors.inputs.forEach(input => {
      const textboxApi = input['c-textbox']
      this.fields.push(textboxApi)
      // setTimeout is necessary to prioritize the submit event over the blur event if a submit and blur event is triggered at the same time, the 150ms is the necessary amount to make that happen
      textboxApi.events.on('blur', () => setTimeout(() => textboxApi.validate(), 150), this)
    })
    this.selectors.textareas.forEach(textarea => {
      const textareaApi = textarea['c-textarea']
      this.fields.push(textareaApi)
      textareaApi.events.on('blur', () => textareaApi.validate(), this)
    })
    this.selectors.selects.forEach(select => {
      const dropdownApi = select['c-dropdown']
      this.fields.push(dropdownApi)
      dropdownApi.events.on('change', () => dropdownApi.validate(), this)
    })
    this.selectors.choices.forEach(choice => {
      // Next line is for preventing errors if there's a choice-list component inserted with "jsApi": false parameter.
      if (!choice['c-choice-list']) return false
      const choiceListApi = choice['c-choice-list']
      this.fields.push(choiceListApi)
      choiceListApi.events.on('changeOptions', () => choiceListApi.validate(), this)
    })
    this.selectors.dates.forEach(date => {
      const dateApi = date['c-date-selector']
      this.fields.push(dateApi)
    })
    this.selectors.phones.forEach(phone => {
      const phoneApi = phone['c-phone-input']
      this.fields.push(phoneApi)
    })
    return this
  }

  _bindChangePatternEvents () {
    // init
    this.changePatternFields = {}
    const customValidationsSettings = this.element.querySelectorAll(componentQueries.changePatternSettings)
    customValidationsSettings.forEach(config => {
      try {
        const parsed = JSON.parse(config.innerText)
        this.changePatternFields = { ...this.changePatternFields, ...parsed }
      } catch (err) {
        if (window.newrelic) {
          window.newrelic.noticeError(err)
        }
      }
    })
    const triggerFields = this.element.querySelectorAll(componentQueries.triggersChangePatternSelector)
    triggerFields.forEach(field => {
      const dropdownApi = field['c-dropdown']
      if (!dropdownApi) {
        return
      }

      // update with current value
      const currentValue = dropdownApi.getProp('value')
      if (currentValue) {
        this._updateInputPatterns(field, currentValue)
      }

      // attach events
      dropdownApi.events.on('change', (value) => {
        this._updateInputPatterns(field, value)
      }, this)
    })

    return this
  }

  _updateInputPatterns (triggerField, value) {
    const fieldSettingsId = triggerField.getAttribute(attr.changePatternSettingsId)
    if (fieldSettingsId === null || fieldSettingsId === '') {
      return
    }
    const triggerSettings = this.changePatternFields[fieldSettingsId]
    if (triggerSettings) {
      triggerSettings.forEach(targetInputSettings => {
        const newPattern = targetInputSettings.patterns.find(pattern => pattern.key === value.toLowerCase())
        if (!newPattern) {
          return
        }
        let query = `[${attr.changePatternFieldSelector}=${targetInputSettings.fieldSelector}] input`
        const startId = triggerField.getAttribute(attr.changePatternIdStart)
        if (startId !== null && startId !== '') {
          query += `[id^="${startId}"]`
        }
        const targetInput = this.element.querySelector(query)
        if (targetInput) {
          targetInput.pattern = newPattern.pattern
        }
      }, this)
    }
  }

  _bindRecaptchaEvents () {
    this.element.addEventListener('recaptcha-form-submit', this._submitEvent.bind(this), false)
  }

  _bindSubmit () {
    this.element.addEventListener('submit', this._submitEvent.bind(this), false)
  }

  _submitEvent (ev) {
    if (this.options.validate && this.validate().some(v => !v.isValid)) {
      // KO
      ev.preventDefault()
      this.element.dispatchEvent(new window.CustomEvent('form-validation-failed'))
    } else {
      // OK
      this.events.emit('submitted', this.getFormData())
      if (this.options.disabledOnSubmit) {
        if (this._getSubmitButton()) this._getSubmitButton().disabled = true
      }
      if (this.options.loadingOnSubmit) {
        if (this._getSubmitButton()) this._getSubmitButton()['c-btn'].setLoading(true)
      }
      if (ev.type === 'recaptcha-form-submit') {
        this.element.submit()
      }
    }
  }

  _getSubmitButton () {
    return this.element.querySelector('[type="submit"]')
  }

  validate () {
    let wrongField = null
    const validations = []
    this.fields.forEach(field => {
      field.enableErrors()
      const validation = field.validate()
      if (!validation.isValid) {
        wrongField = wrongField || field
      }
      validations.push(validation)
    })

    if (wrongField) {
      wrongField.element.focus()
    }

    this.events.emit('validated', validations)
    return validations
  }

  getFormData (options) {
    options = { format: 'formData', defaultValue: '', inputsConfigs: null, ...options }
    let inputsConfig = this.formInputsSelectors
    if (options.inputsConfig) {
      inputsConfig = this._mergeInputsOverrides(this.formInputsSelectors, options.inputsConfig)
    }
    const useJsonFormat = options.format && options.format.toLowerCase() === 'json'
    const formData = useJsonFormat ? {} : new window.FormData()

    inputsConfig.forEach(input => {
      const inputItems = this.element.querySelectorAll(`.c-form__item.${input.api}`)
      inputItems.forEach(inputElement => {
        // Use id instead of name to support components that have multiple inputs (e.g.: c-date-selector)
        const key = inputElement.id ? inputElement.id : inputElement.querySelector('input,select').id
        const value = input.functionOverride ? input.functionOverride((inputElement[input.api])) : input.function(inputElement[input.api], input.propName)
        const valueToSave = value || options.defaultValue
        if (useJsonFormat) {
          formData[key] = valueToSave
        } else {
          formData.append(key, valueToSave)
        }
      })
    })

    return formData
  }

  _mergeInputsOverrides (originalInputs, overrideInputs) {
    const merged = originalInputs.map(function (apiConfig) {
      const apiOverride = overrideInputs.find(inputSelector => inputSelector.api === apiConfig.api)
      if (apiOverride) {
        apiConfig = { ...apiConfig, ...apiOverride }
      }
      return apiConfig
    })
    return merged
  }

  _getPropValue (componentApi, propName) {
    return componentApi.getProp(propName)
  }

  _getChoiceListValue (componentApi) {
    let value = ''
    const selectedValues = componentApi.getSelectedValues()
    if (selectedValues.length > 0) {
      value = selectedValues.join()
    }
    return value
  }
}

registerComponent(Form, definition.name, definition)
