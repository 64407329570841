import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { FormFieldsetTemplate } from './c-form__fieldset.template'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'
import { defaultBoxData, BoxTemplate } from '../box/c-box.template'

/**
 * The FormSection contains all data needed to hydrate a Form Section view
 *
 * @typedef {Object}          FormSectionData
 *
 * @property {String}         title                 - Title of the section
 * @property {String}         extraClasses          - Extra classes to be rendered
 * @property {String}         html                  - Content of the section
 * @property {String}         fieldsets             - List of fieldsets to render automatically in replacement of html field
*/
export const defaultFormSectionData = {
  canBeCollapsed: false,
  collapseId: ''
}

export const FormSectionTemplate = (d) => {
  d = { ...defaultFormSectionData, ...d }

  return `
    <div class="c-form__section ${d.canBeCollapsed ? 'c-form__section--collapsed' : ''} ${d.extraClasses ? d.extraClasses : ''}">
      ${d.canBeCollapsed
        ? `${BoxTemplate({
            ...defaultBoxData,
            variant: 'flat',
            attributes: {
              'data-c-collapse__id': d.collapseId,
              'data-c-collapse__action': 'toggle',
              'data-c-form__collapse-header': ''
            },
            extraClasses: 'c-form__collapse-trigger c-collapse__trigger--smooth cs-default u-shadow--elevated u-radius--large',
            html: `
              ${d.icon ? `<span class="c-form__section-heading-icon m-icon m-icon--size-medium m-icon--${d.icon}"></span>` : ''}
              ${HeaderSectionTemplate(d)}
              <div class="c-form__section-collapse-icon">
                <span class="m-icon m-icon--size-medium m-icon--chevron-down c-collapse__trigger-icon c-form__section-collapse-icon--hided"></span>
                <span class="m-icon m-icon--size-medium m-icon--chevron-up c-collapse__trigger-icon c-form__section-collapse-icon--shown"></span>
              </div>
              `
            })
          }
          ${CollapseTemplate({
            ...defaultCollapseData,
            id: d.collapseId,
            extraClasses: 'c-form__section--collapse',
            attributes: {
              'data-c-form__section-collapse': d.collapseId
            },
            html: `
              ${BodySectionTemplate(d)}
            `
            })}
          `
        : `
            ${HeaderSectionTemplate(d)}
            ${BodySectionTemplate(d)}
          `
        }  
    </div>
    `
}

const HeaderSectionTemplate = (d) => {
  return `
    ${d.title
      ? `<div class="c-form__section-title">
          ${TitleTemplate({
            ...defaultTitleData,
            ...{
              text: d.title,
              size: 'small',
              extraClasses: 'c-form-section__title'
            }
          })}
        </div>`
      : ''}
    `
}

const BodySectionTemplate = (d) => {
  return `
    ${d.html ? d.html : ''}
      
    ${d.fieldsets
        ? d.fieldsets.map(fieldset => FormFieldsetTemplate(fieldset)).join('')
        : ''}
  `
}
