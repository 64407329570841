/**
 * List of events used by booking steps widget
 *
 */
export const bookedServiceEdit = {
  BOOKED_SERVICE_EDIT_FETCHED: 'BookedServiceEdit.BookedServiceFetched',
  BOOKED_SERVICE_EDIT_FETCHED_ITEM_CHANGED: 'BookedServiceEdit.BookedServiceFetchedItemChanged',
  SAVE_CHANGES_PROCESS_STARTED: 'BookedServiceEdit.SaveChangesProcessStarted',
  SAVE_CHANGES_PROCESS_FAILED: 'BookedServiceEdit.SaveChangesProcessFailed',
  CONDITIONS_VALIDATION_FAILED: 'BookedServiceEdit.ConditionsValidationFailed',
  SAVE_CHANGES_TO_BOOKING_FAILED: 'BookedServiceEdit.SaveChangesToBookingFailed',
  SAVE_CHANGES_TO_BOOKING_SUCCESS: 'BookedServiceEdit.SaveChangesToBookingSuccess',
  API_CALL_FAILED: 'BookedServiceEdit.ApiCallFailed',
  ANCILLARY_DATA_CHANGED: 'BookedServiceEdit.AncillaryDataChanged',
  BOOKED_SERVICE_EDIT_SERVICE_AVAILABLE: 'BookedServiceEdit.ServiceAvailable',
  BOOKED_SERVICE_EDIT_SERVICE_NOT_AVAILABLE: 'BookedServiceEdit.ServiceNotAvailable'
}
