/**
 * List of events used by booking car rental v2 widget
 *
 */
export const bookingCarRental = {
  RENDER_EDIT_DETAILS_TEMPLATE: 'BookingCarRental.RenderEditDetailsTemplate',
  EQUIPMENTS_CHANGED: 'BookingCarRental.EquipmentsChanged',
  CREDIT_CARD_CHANGED: 'BookingCarRental.CreditCardChanged',
  SLIDER_BUTTON_CLICKED: 'BookingCarRental.SliderButtonClicked'
}
