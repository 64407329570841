export const bookingFilterableServices = {
  BOOKING_FILTERABLE_SERVICES_CONTAINER_TOGGLE: 'BookingFilterableServices.ContainerToggle',
  BOOKING_FILTERABLE_SERVICES_SWITCH_TOGGLE: 'BookingFilterableServices.SwitchToggle',
  BOOKING_FILTERABLE_SERVICES_CANCEL_BUTTON: 'BookingFilterableServices.CancelButton',
  BOOKING_FILTERABLE_SERVICES_FILTER_SPORT: 'BookingFilterableServices.FilterSport',
  BOOKING_FILTERABLE_SERVICES_FILTER_DURATION: 'BookingFilterableServices.FilterDuration',
  BOOKING_FILTERABLE_SERVICES_FILTER_MATERIAL_CATEGORY: 'BookingFilterableServices.MaterialCategory',
  BOOKING_FILTERABLE_SERVICES_FILTER_SPORT_LEVEL: 'BookingFilterableServices.SportLevel',
  BOOKING_FILTERABLE_SERVICES_CHOICE_LIST: 'BookingFilterableServices.ChoiceList',
  BOOKING_FILTERABLE_SERVICES_SAVE_BUTTON: 'BookingFilterableServices.SaveButton'
}
