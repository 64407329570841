/**
 * The RatingData contains all data needed to hydrate a Rating view
 *
 * @typedef {Object}            RatingData
 *
 * @property {String}           value            - The value of the rating
 * @property {String}           size             - The size of the rating. Values can be: default,xs,s,l,xl
 * @property {String}           [extraClasses]   - Extra classes to be appended
 */

export const defaultRatingData = {
  value: '',
  size: '',
  extraClasses: ''
}

export function RatingTemplate (model) {
  return `<span data-rating='${model.value}' class='c-rating ${model.size ? 'c-rating--' + model.size : ''} ${model.extraClasses || ''}'></span>`
}
