import { getAbsoluteUrl } from '../../../js/document/url'
import { forceRepaint, getComputedStyle } from '../../../js/document/css'
import { requestAnimationFrame } from '../../../js/document/requestAnimationFrame'
import { observerAPI, documentObserver } from '../../../js/document/intersector'
import imageSizes from '../../../js/document/image-sizes'
import domEventsHelper from '../../../js/document/dom-events-helper'
import registeredEvents from '../../../js/helpers/registered-events'
const EventEmitter = require('eventemitter3')

const componentAPI = 'c-img'
const knownSizeThreshold = 20
const sizePatterns = {
  width: '{W}',
  height: '{H}'
}
const defaultWidth = 320
const defaultHeight = 240

const classNames = {
  placeholder: 'c-img__placeholder',
  resolved: 'c-img__resolved',
  keepOriginalSize: 'data-c-img__keep-original-size',
  resolvedState: 'is-resolved',
  resolvingState: 'is-resolving'
}

const loadImageRetries = 5

const attr = {
  track: 'data-track'
}

/**
 * Img component
 */
export default class Img {
  /**
   * Creates a new img behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {Element} element - The HTML element.
   */
  constructor (element) {
    this.element = element
    this.placeholderElement = this.element.querySelector(`.${classNames.placeholder}`)
    this.events = new EventEmitter()
    this.asyncSrc = this.element.dataset.imgSrc
    this.sync = [...this.element.classList].includes('c-img--sync')
    this.keepOriginalSize = this.element.dataset.cImg__keepOriginalSize !== undefined
    this.isResolving = false
    this.isResolved = false
    this.imgDimensions = null
    this.loadImageRetries = loadImageRetries

    // whenToResolve can be load, intersect, or missing (on demand)
    const whenToResolve = this.element.dataset.cImg__resolve

    if (!this.sync && whenToResolve === 'load') {
      requestAnimationFrame(() => {
        window.setTimeout(() => this.resolve(), 35)
      })
    }

    if (!this.sync && whenToResolve === 'intersect') {
      const observer = documentObserver()
      observer.observe(this.element)
      this.element[observerAPI].events.on('enter', () => {
        this.resolve()
        observer.unobserve(this.element)
      })
    }

    element[componentAPI] = {
      element: this.element,
      events: this.events,
      isResolving: this.isResolving,
      isResolved: this.isResolved,
      imgDimensions: this.imgDimensions,
      getElementDimensions: this.getElementDimensions.bind(this),
      _instance: this,
      resolve: () => this.resolve()
    }

    this.element.addEventListener('click', () => {
      this.onClick()
    })

    registeredEvents.registerComponentEvents(componentAPI, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  onClick () {
    this.events.emit('click')
  }

  async resolve () {
    if (this.isResolving || this.isResolved) return Promise.resolve()
    this.isResolving = true
    this.element.classList.add(classNames.resolvingState)
    return new Promise((resolve) => {
      window.requestAnimationFrame(() => {
        this.imgResource = new window.Image()
        this.getElementDimensions()
        this._resourcesEvents = [
          [this.imgResource, { load: (ev) => this._handleImageLoaded(ev, resolve) }],
          [this.imgResource, { error: (ev) => this._handleImageError(ev, resolve) }]
        ]
        domEventsHelper.attachEvents(this._resourcesEvents, componentAPI)
        this.imgResource.src = this._resolveImgUrl()
      })
    })
  }

  getElementDimensions () {
    let height = parseFloat(getComputedStyle(this.element, 'height'))
    let width = parseFloat(getComputedStyle(this.element, 'width'))

    if (isNaN(width) || isNaN(height)) {
      width = defaultWidth
      height = defaultHeight
    }

    this.imgDimensions = {
      height,
      width
    }
    return this.imgDimensions
  }

  _resolveImgUrl () {
    let imgSrc = getAbsoluteUrl(this.asyncSrc)
    if (this.keepOriginalSize || !imgSrc.includes(sizePatterns.width) || !imgSrc.includes(sizePatterns.height)) return imgSrc
    const imgRatio = this.imgDimensions.height / this.imgDimensions.width
    const nearestKnownSize = imageSizes.filter(knownSize => knownSize.width >= (this.imgDimensions.width - knownSizeThreshold))[0] || imageSizes[imageSizes.length - 1]
    const newImgDimensions = {
      height: Math.round(nearestKnownSize.width * imgRatio),
      width: nearestKnownSize.width
    }
    imgSrc = imgSrc.replace(sizePatterns.width, newImgDimensions.width).replace(sizePatterns.height, newImgDimensions.height)
    return imgSrc
  }

  _handleImageLoaded (e, resolve) {
    // Resolve current element
    const placeholderElement = this.element.querySelector(`.${classNames.placeholder}`)
    const cloneImgResource = this.imgResource.cloneNode()
    cloneImgResource.classList.add(classNames.resolved, ...[...placeholderElement.classList].filter(className => className !== classNames.placeholder))
    const placeholderAttributes = [...placeholderElement.attributes].filter(attr => attr.name !== 'src' && attr.name !== 'class')
    placeholderAttributes.forEach(attr => cloneImgResource.setAttribute(attr.name, attr.value))
    // Schedule transformations for next animation frame
    window.requestAnimationFrame(() => {
      this.element.appendChild(cloneImgResource)
      window.requestAnimationFrame(() => {
        this.element.classList.add(classNames.resolvedState)
        forceRepaint(this.element)
        const handleTransitionEnd = () => {
          if (this.element.contains(placeholderElement)) this.element.removeChild(placeholderElement)
          this.element.classList.remove(classNames.resolvingState)
          this.isResolving = false
          this.isResolved = true
          resolve(true)
          cloneImgResource.removeEventListener('transitionend', handleTransitionEnd)
        }
        cloneImgResource.addEventListener('transitionend', handleTransitionEnd, false)
      })
    })
    // Trigger resolve similar candidates
    const similarCandidatesToBeResolved = this._getSimilarCandidatesToBeResolved()
    similarCandidatesToBeResolved.forEach((candidate) => candidate[componentAPI].resolve())
    // Remove resource event listener
    domEventsHelper.detachEvents(this._resourcesEvents, componentAPI)
  }

  _handleImageError (e, resolve) {
    if (this.loadImageRetries > 0) {
      window.setTimeout(() => {
        this.imgResource.src = this._resolveImgUrl()
      }, 100)
      this.loadImageRetries--
    }
  }

  _getSimilarCandidatesToBeResolved () {
    return [...document.querySelectorAll(`[data-js-component*="${componentAPI}"][data-img-src="${this.element.dataset.imgSrc}"]`)]
      .filter((el) => {
        // Skip unInstantiated components and ones resolving/resolved
        if (
          el === this.element ||
          !el.classList ||
          !el[componentAPI] ||
          el[componentAPI].isResolving ||
          el[componentAPI].isResolved
        ) return false
        const elImgDimensions = el[componentAPI].imgDimensions
          ? el[componentAPI].imgDimensions
          : el[componentAPI].getElementDimensions()
        return (
          this.imgDimensions.height === elImgDimensions.height &&
          this.imgDimensions.width === elImgDimensions.height
        )
      })
  }

  /**
   * Creates a new img for every element on document with targeted attributes.
   *
   * @returns {Img[]} The created instances.
   */
  static createInstancesOnDocument (htmlFragment = window.document) {
    const currentComponents = htmlFragment.querySelectorAll(`[data-js-component*="${componentAPI}"]`)
    const instances = []
    for (let i = 0; i < currentComponents.length; i++) {
      instances.push(Img.createInstanceOnElement(currentComponents[i]))
    }
    return instances
  }

  /**
   * Creates a new img for single element.
   *
   * @param {HTMLElement} element - The targeted HTML element.
   * @returns {Img} Self instance.
   */
  static createInstanceOnElement (element) {
    if (element[componentAPI] !== undefined) return null
    return new Img(element)
  }
}
