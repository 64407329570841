
export const favorite = {
  FAVORITE_MODAL_OPEN: 'favorite.modalOpen',
  FAVORITE_MODAL_CLOSE: 'favorite.modalClose',
  FAVORITE_LOGIN: 'favorite.login',
  FAVORITE_SIGNUP: 'favorite.signup',
  FAVORITE_SEARCH: 'favorite.search',
  FAVORITE_ACCO: 'favorite.acco',
  FAVORITE_PAGE: 'favorite.page',
  FAVORITE_ADD: 'favorite.add',
  FAVORITE_REMOVE: 'favorite.remove'
}
