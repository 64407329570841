
/**
 * toCamelCase
 * Converts a string to camelcase.
 * Break the string into words and combine them capitalizing the first letter of each word, using a regexp.
 * Extracted from: https://30secondsofcode.org/#tocamelcase
 *
 * @param {String} str
 * @returns {String}
 *
 * Examples:
 * toCamelCase('some_database_field_name'); // 'someDatabaseFieldName'
 * toCamelCase('Some label that needs to be camelized'); // 'someLabelThatNeedsToBeCamelized'
 * toCamelCase('some-javascript-property'); // 'someJavascriptProperty'
 * toCamelCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'someMixedStringWithSpacesUnderscoresAndHyphens'
 */
export const toCamelCase = str => {
  const s =
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('')
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

/**
 * fromCamelCase
 * Converts a string from camelcase.
 * Break the string into words and combine them with the separator provided the first letter of each word, using a regexp.
 * Extracted from: https://30secondsofcode.org/#tocamelcase
 *
 * @param {String} str
 * @param {String} separator
 * @returns {String}
 *
 * Examples:
 * fromCamelCase('someAttributeValue'); // 'some-attribute-value'
 * fromCamelCase('someExamplePlaced', '_'); // 'some_example_placed'
 */
export const fromCamelCase = (str, separator = '-') => {
  return str && str.replace(/([A-Z])/g, `${separator}$1`).toLowerCase()
}

/**
 * decodeHtml
 * Decode a string that could contain some HTML entities to ready to show text
 *
 * @param {String} txt
 * @returns {String}
 */
const decoderTextArea = document.createElement('textarea')
export const decodeHtml = txt => {
  decoderTextArea.innerHTML = txt
  return decoderTextArea.value
}

/**
 * encodeHtml
 * Encode a string that could contain some HTML text to be shown safe inside html
 *
 * @param {String} text
 * @returns {String}
 */
export const encodeHtml = text => {
  return text.replace(/./gm, function (s) {
    return (s.match(/[a-z0-9\s]+/i)) ? s : '&#' + s.charCodeAt(0) + ';'
  })
}

/**
 * encodeAttributes
 * Encode object to be translated into html tag attributes
 *
 * @param {Object} attributes
 * @returns {String}
 */
export const encodeAttributes = obj => {
  return Object.entries(obj).reduce((str, [key, value]) => str + `${key}="${(value && typeof (value) === 'string' ? value.replace(/"/g, '\'') : value)}" `, '')
}

/**
 * decodeUriParams
 * Decode URI string into an object
 *
 * @param {String} str - uri to decode
 * @returns {Object}
 */
export const decodeParams = str => str.split(/[&?]/).filter((pairValue) => pairValue).reduce((obj, pairValue) => {
  const pairValues = pairValue.split('=')
  return { ...obj, [pairValues[0]]: pairValues[1] }
}, {})

/**
 * cleanUpSpecialCharacters
 * replace the special characters for the ones that we want
 * @param {String} str
 * @param {String} separator
 * @returns {String}
 */
export const cleanUpSpecialCharacters = (str, separator = '') => {
  return str && str.replace(/[^-a-zA-Z ]/g, separator)
}

/**
 * capitalizeString
 * Change first leter of a string to uppercase
 * @param {String} str
 * @returns {String}
 */
export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
