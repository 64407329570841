import { encodeAttributes } from '../../../js/helpers/string'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { TooltipTemplate, defaultTooltipData } from '../../components/tooltip/c-tooltip.template'

/**
 * The FormData contains all data needed to hydrate a Form view
 *
 * @typedef {Object}              TextboxData
 *
 * @property {String}             [extraClasses]
 * @property {Object}             [tooltip]
 * @property {Array}              [passwordRequirements]
 */
export const defaultTextboxData = {
  id: 'my-textbox',
  name: '',
  state: '',
  type: 'text',
  required: false,
  disabled: false,
  highlighted: false,
  hiddenLabel: false,
  hasClearButton: false,
  value: '',
  placeholder: 'Enter a text',
  icon: false,
  iconPosition: 'left',
  messageTypeMismatch: '',
  messageRequired: '',
  messageInvalid: '',
  patternRule: '',
  clearButtonAttributes: {
    'data-c-textbox__clear': ''
  },
  messages: [],
  hideRequiredSymbol: false,
  tooltip: null,
  passwordRequirements: [],
  text: null,
  textPosition: 'left'
}

export const TextboxTemplate = (d) => {
  d = { ...defaultTextboxData, ...d }
  return `
    <div class="c-textbox
            ${d.disabled ? 'is-disabled' : ''}
            ${d.required ? 'is-required' : ''}
            ${d.state ? `has-${d.state}` : ''}
            ${d.highlighted ? 'c-textbox--highlighted' : ''}
            ${d.hideRequiredSymbol ? 'c-textbox--without-required-symbol' : ''}
            ${d.extraClasses ? d.extraClasses : ''}
            "
            ${d.attributes ? encodeAttributes(d.attributes) : ''}
            data-js-component="c-textbox"
            >

        ${d.hiddenLabel
          ? `<label aria-hidden="true" aria-label="${d.label}" for="${d.id}"></label>`
          : d.label
            ? `${d.tooltip ? '<div class="e-form-label__container">' : ''}
            <label class="c-textbox__label e-form-label" for="${d.id}">${d.label}</label>
            ${d.tooltip ? `${TooltipTemplate({ ...defaultTooltipData, ...d.tooltip })} </div>` : ''}`
            : ''}

          <div class="c-textbox__wrapper">
            ${d.text
              ? `<span class="c-textbox__text c-textbox__text--${d.textPosition ? d.textPosition : 'left'}">${d.text}</span>`
              : ''}
            ${d.icon
              ? `<span class="c-textbox__icon m-icon--${d.icon} c-textbox__icon--${d.iconPosition && d.type !== 'password' ? d.iconPosition : 'left'}"></span>`
              : ''}
            ${d.type === 'password'
              ? '<span class="c-textbox__icon m-icon--eye c-textbox__icon--right c-textbox__icon--password is-hidden"></span>'
              : ''}
              <input type="${d.type}"
                    class="c-textbox__element"
                    placeholder="${d.placeholder ? d.placeholder : ''}"
                    name="${d.name ? d.name : d.id}"
                    id="${d.id}"
                    value="${d.value}"
                    ${d.elementAttributes ? encodeAttributes(d.elementAttributes) : ''}
                    autocomplete="${d.autocomplete ? 'on' : 'off'}"
                    ${d.readonly ? 'readonly' : ''}
                    ${d.disabled ? 'disabled' : ''}
                    ${d.required ? 'required' : ''}
                    ${d.messageTypeMismatch ? `data-message-typeMismatch="${d.messageTypeMismatch}"` : ''}
                    ${d.messageRequired && d.messageRequired !== '' ? `data-message-required="${d.messageRequired}"` : ''}
                    ${d.messageInvalid && d.messageInvalid !== '' ? `data-message-pattern="${d.messageInvalid}"` : ''}
                    ${d.patternRule && d.patternRule !== '' ? `pattern="${d.patternRule}"` : ''}
                    />
            ${d.hasClearButton
              ? BtnTemplate({
                ...defaultButtonData,
                ...{
                  variant: 'icon-only',
                  icon: 'cross',
                  text: '',
                  extraClasses: 'c-textbox__clear',
                  attributes: d.clearButtonAttributes
                }
              })
              : ''}
          </div>
        ${d.hiddenMessages
          ? ''
          : `<div class="c-textbox__messages">
            ${d.messages
              ? d.messages.map(message => `
                <div class="c-textbox__message">
                  <i class="m-icon m-icon--warning-circle"></i>
                  <span>${message}</span>
                </div>`).join('')
              : ''}
          </div>
        `}
        ${d.type === 'password' && d.passwordRequirements
          ? `
          <ul class="c-textbox__password-requirements-container" data-w-textbox__password-requirements-container>
            ${d.passwordRequirements.map(passwordRequirement => `
              <li class="c-textbox__password-requirement" ${encodeAttributes(passwordRequirement.attributes)}>
                <span class="c-textbox__password-requirement-icon m-icon--checkmark"></span>
                <span class="c-textbox__password-requirement-text">${passwordRequirement.text}</span>
              </li>
            `).join('')}
          </ul>
          `
        : ''}
    </div>
    `
}
