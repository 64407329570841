/**
 * The ButtonData contains all data needed to hydrate a Button view
 *
 * @typedef {Object}          ButtonData
 *
 * @property {String}         extraClasses
 * @property {String}         type                - Button's type
 * @property {String}         text                - Button's text
 * @property {String}         [icon]
 * @property {String}         [iconPosition]
 * @property {String}         [variant]           - Button's variant
 * @property {Boolean}        [block]             - If true, button fills its whole wrapper
 * @property {Boolean}        [loading]           - If true, button attaches a loader
 * @property {Boolean}        [disabled]          - If true, button is not clickable
 * @property {Boolean}        [jsApi]             - If true, button will init its API
 * @property {Object}         [link]              - Link info for button in case it's an anchor link
 * @property {Object}         [link.href]         - Link's url
 * @property {Object}         [link.target]       - Open in same page, new page...
 * @property {Object}         [link.title]
 * @property {Object}         [attributes]        - Attributes for the button
 */

import { encodeAttributes } from '../../../js/helpers/string'
import { LoaderTemplate } from '../loader/c-loader.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-btn__tokens.json')['c-btn']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/btn/data/c-btn__tokens.json`)['c-btn'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultButtonData = {
  extraClasses: '',
  type: 'button',
  icon: '',
  iconPosition: 'left',
  variant: 'default',
  text: '',
  block: false,
  loading: false,
  disabled: false,
  jsApi: true,
  link: {},
  track: '',
  attributes: {}
}

export const BtnTemplate = (d) => {
  d = { ...defaultButtonData, ...d }
  return `
<${d.link && d.link.href ? 'a' : 'button'}
  class="c-btn ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} ${d.block ? 'c-btn--block' : ''} ${d.loading ? 'c-btn--loading' : ''} ${d.extraClasses}"
  ${d.link && d.link.href ? '' : `type="${d.type}"`}
  ${d.disabled ? 'disabled' : ''}
  ${d.jsApi ? 'data-js-component="c-btn"' : ''}
  ${d.link && d.link.href ? `href="${d.link.href}"` : ''}
  ${d.link && d.link.target ? `target="${d.link.target}"` : ''}
  ${d.link && d.link.title ? `title="${d.link.title}"` : ''}
  ${d.track ? `data-track=${d.track}` : ''}
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.icon && (d.iconPosition === 'default' || d.iconPosition === 'left' || d.variant === 'icon-only') ? `<i class="c-btn__icon ${getTokenClass('icon-position', d.iconPosition, tokens)} m-icon--${d.icon}"></i>` : ''}
  <span class="c-btn__text">${d.text}</span>
  ${d.icon && (d.iconPosition === 'right') ? `<i class="c-btn__icon ${getTokenClass('icon-position', d.iconPosition, tokens)} m-icon--${d.icon}"></i>` : ''}
  ${d.loading ? LoaderTemplate({ size: 'tiny', extraClasses: 'c-btn__loader' }) : ''}
</${d.link && d.link.href ? 'a' : 'button'}>
`
}
