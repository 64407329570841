import { getUrlFromString } from '../../../js/document/url'
import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { VoucherRedeemerTemplate } from './w-voucher-redeemer.template'
import { elementFromString, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import registeredEvents from '../../../js/helpers/registered-events'
import { voucherRedeemerEvents } from '../../../js/document/event-types'

const EventEmitter = require('eventemitter3')

const widgetApi = 'w-voucher-redeemer'

const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  submitButton: `[data-${widgetApi}--submit-button]`,
  cancelButton: `[data-${widgetApi}--cancel-button]`,
  voucherCodeInput: `[data-${widgetApi}--voucher-code-input]`,
  emailInput: `[data-${widgetApi}--email-input]`,
  birthdateSelector: `[data-${widgetApi}--birthdate-selector]`,
  errorMessageContainer: `[data-${widgetApi}--error-message-container]`
}

const attr = {
  submitButtonText: `data-${widgetApi}--submit-button-text`,
  submitButtonCheckingText: `data-${widgetApi}--submit-button-checking-text`,
  componentId: 'componentId',
  url: 'url',
  method: 'method',
  track: 'data-track'
}

export default class VoucherRedeemer {
  /**
   * Creates a new Voucher Redeemer
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach Voucher Redeemer
   * @param options
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = options
    this.events = new EventEmitter()
    this.element[widgetApi] = this
    this.apis = {}

    if (options.data) {
      this._createHtml()
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    this._getHtmlElements()
    this._attachEvents()
  }

  destroy () {
    this.element[widgetApi] = null
    delete this.element[widgetApi]
  }

  _createHtml () {
    const voucherRedeemerHtml = elementFromString(VoucherRedeemerTemplate(this.options.data))
    moveChildrenFrom(voucherRedeemerHtml, this.element, { flush: true, attributes: true })
    Component.initDocumentComponentsFromAPI(this.element)
  }

  _getHtmlElements () {
    this.url = this.options.url || this.element.dataset[attr.url]
    this.method = this.options.method || this.element.dataset[attr.method]
    this.errorMessageContainer = this.element.querySelector(widgetQueries.errorMessageContainer)
    this.componentId = this.options.componentId || this.element.dataset[attr.componentId]
  }

  _attachEvents () {
    const form = this.element.querySelector(widgetQueries.form)
    if (form) {
      this.apis = { ...{ form: form['c-form'] }, ...this._getFormControls(form) }
    }

    this.submitButton = this.element.querySelector(widgetQueries.submitButton)
    if (this.submitButton) {
      this.submitButton.addEventListener('click', this._clickSubmitButton.bind(this))
      this.apis.submitButton = this.submitButton['c-btn']
    }

    this.cancelButton = this.element.querySelector(widgetQueries.cancelButton)
    if (this.cancelButton) {
      this.apis.cancelButton = this.cancelButton['c-btn']
      this.apis.cancelButton && this.apis.cancelButton.events.on('clickButton', () => this.events.emit('canceled'))
    }
  }

  _getFormControls (formEl) {
    const elements = {
      voucherCode: formEl.querySelector(widgetQueries.voucherCodeInput),
      email: formEl.querySelector(widgetQueries.emailInput),
      birthdate: formEl.querySelector(widgetQueries.birthdateSelector)
    }
    return {
      voucherCode: elements.voucherCode && elements.voucherCode['c-textbox'],
      email: elements.email && elements.email['c-textbox'],
      birthdate: elements.birthdate && elements.birthdate['c-date-selector']
    }
  }

  async _clickSubmitButton (ev) {
    ev.preventDefault()

    this._setButtonsState(true)
    this._setResultState()

    if (!this.apis.form || this.apis.form.validate().some(v => !v.isValid)) {
      this._setButtonsState(false)
      return
    }

    const data = {
      voucherCode: this.apis.voucherCode ? this.apis.voucherCode.getProp('value') : '00000000-0000-0000-0000-000000000000',
      email: this.apis.email ? this.apis.email.getProp('value') : '',
      birthdate: this.apis.birthdate ? this.apis.birthdate.getProp('rawDate') : '',
      contextItemId: this.componentId
    }

    const result = await this._asyncCall(data)

    if (result.success && result.response.isValid) {
      this.events.emit('voucherFetched', result.response)
    }
  }

  async _asyncCall (data) {
    const url = getUrlFromString(this.url)
    const options = {
      ...(this.method) && { method: this.method },
      ...(this.method && this.method.toUpperCase() === 'POST') && { body: data }
    }
    const result = await apiCaller(url.href, options)

    this._setButtonsState(false)
    if (result.success && result.response.isValid) {
      this._setResultState()
    } else if (result.success && !result.response.isValid) {
      this.events.emit(voucherRedeemerEvents.VOUCHER_REDEEMER_SERVER_VALIDATION_ERROR,
        {
          message: this.errorMessageContainer.textContent,
          voucherCode: data.voucherCode
        })
      this._setResultState('error')
    } else {
      this.events.emit(voucherRedeemerEvents.VOUCHER_REDEEMER_SERVER_ERROR,
        {
          message: this.errorMessageContainer.textContent,
          voucherCode: data.voucherCode
        })
      this._setResultState('error')
    }

    return result
  }

  _setButtonsState (isLoading) {
    if (this.submitButton) {
      const text = isLoading ? this.submitButton.getAttribute(attr.submitButtonCheckingText) : this.submitButton.getAttribute(attr.submitButtonText)
      this.apis.submitButton && this.apis.submitButton.setProps({ disabled: isLoading, loading: isLoading })
      if (text) this.apis.submitButton && this.apis.submitButton.setProp('text', text)
    }
    this.apis.cancelButton && this.apis.cancelButton.setProp('disabled', isLoading)
  }

  _setResultState (value = '') {
    this.apis.voucherCode && this.apis.voucherCode.setProp('state', value)
    this.apis.email && this.apis.email.setProp('state', value)
    this.apis.birthdate && this.apis.birthdate.setProp('state', value)
    this.errorMessageContainer.style.display = value === 'error' ? 'block' : 'none'
  }
}

registerWidget(VoucherRedeemer, widgetApi)
