import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { FormSectionTemplate, defaultFormSectionData } from './c-form__section.template'
import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The FormData contains all data needed to hydrate a Form view
 *
 * @typedef {Object}              FormData
 *
 * @property {String}             extraClasses
 * @property {String}             html            - Content of the section
 * @property {FormSectionData[]}  sections        - List of sections to render automatically in replacement of html field
 */
export const defaultFormData = {
  loadingOnSubmit: false
}

export const FormTemplate = (d) => {
  d = { ...defaultFormData, ...d }
  return `
    <form class="c-form ${d.extraClasses ? d.extraClasses : ''}"
        ${d.formAction ? 'action=' + d.formAction : ''}
        ${d.formMethod ? 'method=' + d.formMethod : ''}
        data-js-component="c-form"
        ${d.validate ? 'data-validate' : ''}
        ${d.disabledOnSubmit ? 'data-disabled-on-submit' : ''}
        ${d.loadingOnSubmit ? 'data-loading-on-submit' : ''}
        ${d.track ? 'data-track=' + d.track : ''}
        novalidate
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>

    ${d.html ? d.html : ''}

    ${d.sections
      ? `${d.sections.map(section => FormSectionTemplate({ ...defaultFormSectionData, ...section })).join('')}`
      : ''}

    ${d.hasSubmitButton
      ? `${BtnTemplate({
        ...defaultButtonData,
        ...{
          text: d.submitButtonText,
          type: 'submit',
          variant: 'secondary',
          block: false,
          extraClasses: 'c-form__submit-button',
          jsApi: d.loadingOnSubmit
        }
      })}`
      : ''}
    </form>`
}
